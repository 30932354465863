
import { mapActions, mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import RegistrationForm from "../components/authentication/RegistrationForm.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import { ref } from 'vue';
import message from "@/repository/message";

@Component({
  name: "Account",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  computed: {
    ...mapState(["isAuthenticated"]),
    ...mapGetters("user", ["canEnterAdmin"]),
  },

})
export default class Account extends Vue {
  @Prop({ type: String, required: false, default: null })
  token: string | undefined;




  show1 = ref(false);
  show2 = ref(false);
  show3 = ref(false);

  min = 8;
  max = 12;

  email = "";
  oldpassword = "";
  password = "";
  confirmPassword = "";
  notChanged=true

  pwd=false;
  confirmPwd=false;
  old_password=false;

  disableForm = false;
  arePwdSame = true;
  error_content=""
  error_display=false

  data(): any {
    return {
      arePwdSame: true,
      error_content:"",
      error_display:false
    }
  }

  created(){
    if(!this.isAuthenticated && !this.canEnterAdmin){
      this.$router.push({ name: 'login' })
    }
  }

  @Watch("confirmPassword")
  @Watch("password")
  checkConfPwdInput(): void {
    if(this.confirmPassword!=""){
      if (this.confirmPassword == this.password) {
        this.arePwdSame = true;
      }else{
        this.arePwdSame = false;
      }
    }
  }

  get isSubmitDisable(): any {
    return !this.pwd || !this.confirmPwd || !this.arePwdSame || !this.old_password
    // return this.$v.$invalid || !this.disableForm || !this.pwd || !this.arePwdSame || !this.confirmPwd
    // return
  }

  hasLower(text: string): boolean {
    const regex = /[a-z]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasUppercase(text: string): boolean {
    const regex = /[A-Z]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasSymbol(text: string): boolean {
    const regex = /[!@#$%^&*(),.?":{}|<>]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
    //from le back [!@#$%^&*(),.?":{}|<>]
  }

  hasNumber(text: string): boolean {
    const regex = /\d+/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasSpace(text: string): boolean {
    const regex = /[\s]+/;
    this.pwd=this.pwd && !regex.test(text);
    return regex.test(text);
  }

  hasPWD(text: string): boolean {
    if(text.length>0){
      this.pwd=true
      return(true)
    }
    this.pwd=false
    return false;
  }

  hasPWD2(text: string): boolean {
    if(text.length>0){
      this.confirmPwd=true
      return(true)
    }
    this.confirmPwd=false
    return false;
  }

  hasPWD3(text: string): boolean {
    if(text.length>0){
      this.old_password=true
      return(true)
    }
    this.old_password=false
    return false;
  }


  rules = {
    required: value => !! this.hasPWD(value) || 'Le mot de passe est requis',
    min: value => value.length >= this.min ||  `Doit contenir au moins ${this.min} caractères`,
    max: v => v.length <= this.max ||  `Doit contenir moins de ${this.max} caractères`,
    pwdLower: value => !! this.hasLower(value) || 'Doit contenir une lettre minuscule',
    pwdUpper: value => !! this.hasUppercase(value) || 'Doit contenir une lettre majuscule',
    pwdSymbol: v => !! this.hasSymbol(v) || 'Doit contenir un caractère spécial : !@#$%^&*(),.?":{}|<> ',
    pwdSpace: v => ! this.hasSpace(v) || "Ne peut pas contenir d'espace",
    pwdNumber: v => !! this.hasNumber(v) || 'Doit contenir au moins un chiffre',
  }

  rules2 = {
    required: value => !! this.hasPWD2(value) || 'Le mot de passe est requis',
  }

  rules3 = {
    required: value => !! this.hasPWD3(value) || "L 'ancien mot de passe est requis",
  }

  changePWD(){
    console.log(this.oldpassword)
    console.log(this.password)
    console.log(this.confirmPassword)
    this.formLoading=true
    let userId=window.localStorage.getItem("userId")
    // if (this.arePwdSame && this.password) {
      this.$store.dispatch("user/ChangePwd", {
        user_id: userId,
        new_pwd: this.password,
        old_pwd: this.oldpassword,
        next: (response) => {

          this.notChanged=false
        },
        errorNext: (error) => {
          if(error=="Bad Request"){
            this.error_content="L'ancien mot de passe est incorrect"
          }
          else{this.error_content="Une erreur est survenue, veuillez réessayer ultérieurement ou contactez un administrateur."}

          this.error_display=true
          this.formLoading=false;
        }
      })
    // }

  }


  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  getUFErrorMessage(key: string): string {
    return UFFormValidationMessage[key] || "";
  }

}
